<template>
  <div v-if="test || $route.query.answerId">
    <loader v-if="loading"></loader>
    <div v-else-if="status === 'RUNNING' && test.questions.length">
      <div class="mb-2">
        <div class="mb-3 d-flex justify-content-between align-items-center">
          <p>{{ test.test_verbose_name || test.test_name }}</p>
          <p class="gray-text">{{ currentQuestion }}/{{ test.questions.length }}</p>
        </div>
        <div class="mb-3 progress-bar-wrapper">
          <div
            :style="`width: ${(100 / test.questions.length) * currentQuestion}% !important`"
            class="progress-bar-inner"
          ></div>
        </div>
        <div class="mb-3 d-flex justify-content-between align-items-center">
          <b-button @click="reset" class="py-1" variant="outline-default">Заново</b-button>
          <p class="fs-14" v-if="test.time" style="color: #b4bdc1">Осталось {{ formattedClock }}</p>
        </div>
      </div>
      <div :key="question.id" v-for="(question, idx) in test.questions">
        <div v-if="currentQuestion === idx + 1">
          <HeaderQuestion
            ref="currQ"
            @next="next"
            :timeLimit="test.time_for_question"
            :question="question"
            v-model="answeredTest.answers"
          ></HeaderQuestion>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="status === 'TIMEFAIL'">
        <h2>Время вышло</h2>
        <b-button
          variant="outline-default"
          :to="{ name: 'education-tests' }"
          class="w-100 ph-42 mt-5 mb-3"
          >К списку тестов</b-button
        >
        <b-button variant="outline-default" @click="reset" class="w-100 ph-42">Заново</b-button>
      </div>
      <div v-else-if="status === 'FINISHED'">
        <p class="mb-3">Результаты теста</p>

        <h5 class="fw-500 mb-3">
          {{ $route.query.answerId ? 'Пользователь набрал' : 'Вы набрали' }}
          {{ results.filter((val) => val.success).length }}/{{ test.questions.length }} баллов за
          {{ timeSpentVerbose }}
        </h5>
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <b-button
            variant="outline-default"
            @click="$router.go(-1)"
            class="w-100 w-xsm-45 ph-42 mt-3 mb-3"
            >Главное меню</b-button
          >
          <b-button variant="outline-default" @click="reset" class="w-100 w-xsm-45 ph-42"
            >Пройти тест</b-button
          >
        </div>
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <b-button
            v-if="!$route.query.answerId"
            variant="outline-default"
            class="w-100 w-xsm-45 ph-42 mt-3 mb-3"
            v-clipboard:copy="`${window.location.href}&answerId=${answerId}`"
            >Скопировать ссылку на результаты</b-button
          >
        </div>
        <h5 v-if="test.time" class="mt-3">Время: {{ formattedClock }}</h5>

        <div v-if="!$route.query.answerId" class="mt-3">
          <div class="mb-5" v-for="(item, idx) in results" :key="idx">
            <p class="fw-500 mb-2">Шаг {{ idx + 1 }}.</p>
            <question-subject :question="item"></question-subject>
            <div class="ml-3">
              <div class="my-3" v-for="(answer, kidx) in Object.entries(item.body)" :key="kidx">
                <p class="mb-2 fw-500">{{ answer[1].meta.label }}</p>
                <div class="mb-2">
                  <template v-if="answer[1].input_answer === 'TIMEFAIL'">
                    Вы не успели выбрать вариант ответа!
                  </template>
                  <template v-else>
                    <span
                      >Ваш ответ: {{ answer[1].input_answer || 'Не введен'
                      }}{{ answer[1].input_answer.endsWith('!') ? '' : '.' }}
                    </span>
                    <template v-if="answer[1].input_answer == answer[1].answer">
                      <span style="color: green">Верно!</span>
                    </template>
                    <template v-else
                      ><span style="color: red">Неправильно! </span>
                      <br />
                      <div class="mt-2">
                        <span>Верный ответ: </span>
                        <span>{{ answer[1].answer }}. </span>
                        <b-button
                          v-if="answer[1].meta.answer_explanation"
                          style="line-height: 28px !important"
                          class="d-inline-block"
                          variant="link"
                          target="_blank"
                          :href="answer[1].meta.answer_explanation"
                          >Узнать подробнее</b-button
                        >
                      </div></template
                    >
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bottom-part d-flex justify-content-center mb-1 pt-2 pb-2"
      style="padding: 0px 57px; background-color: #f8f8f8; margin-top: 50px"
    >
      <b-button
        style="
          line-break: anywhere !important;
          line-break: wrap !important;
          white-space: pre-wrap !important;
          max-width: 400px !important;
        "
        class="text-center d-block"
        target="_blank"
        href="https://tracker.easyprbot.com/testbugs-form"
        variant="link"
        >Нашли ошибку? Заполните эту форму</b-button
      >
    </div>
  </div>
  <loader v-else></loader>
</template>

<script>
import education from '@main/api/education';
import moment from 'moment';
import timeUtil from '@main/func/timeUtil';
import HeaderQuestion from '@main/components/education/HeaderQuestion.vue';
import MultiStepWindow from '../reusable/windows/MultiStepWindow.vue';
import QuestionSubject from './QuestionSubject.vue';
import Loader from '../Loader.vue';

export default {
  components: { HeaderQuestion, QuestionSubject, Loader },
  props: ['id'],
  data: () => ({
    currentQuestion: 1,
    timeCounter: 0,
    timeSpent: 0,
    timeInterval: 0,
    answeredTest: { name: '', answers: [], time: 0 },
    results: [],
    test: null,
    status: null,
    loading: false,
    answerId: null,
  }),
  currentQuestion: 1,
  methods: {
    async next() {
      if (this.currentQuestion < this.test.questions.length) {
        this.currentQuestion += 1;
        window.scrollTo(0, 0);
      } else if (this.currentQuestion === this.test.questions.length) {
        await this.submit();
      }
    },
    async initTest() {
      this.test = null;
      let test;
      if (this.$route.query.generate) {
        test = await education.v2.tests.generate(this.$route.query);
      } else {
        test = await education.v2.tests.get(this.id);
      }
      this.test = test;
      this.answeredTest.name = test.name;
      // eslint-disable-next-line no-underscore-dangle
      this.answeredTest.id = test._id.$oid;
      clearInterval(this.timeInterval);
      this.status = 'RUNNING';
      this.answeredTest.answers = [];
      this.results = [];
      this.answerId = null;
      this.currentQuestion = 1;
      this.timeInterval = null;
      const { time } = this.test;
      this.timeCounter = time || 0;
      this.timeSpent = 0;
      this.timeInterval = setInterval(() => {
        if (this.timeCounter) {
          this.timeCounter -= 1;
          if (this.timeCounter <= 0) {
            this.timeFail();
          }
        }
        this.timeSpent += 1;
      }, 1000);
    },
    timeFail() {
      this.status = 'TIMEFAIL';
      clearInterval(this.timeInterval);
    },
    reset() {
      this.$router.push({ query: { ...this.$route.query, answerId: null } });
      this.initTest();
    },
    async submit() {
      this.loading = true;
      try {
        clearInterval(this.timeInterval);
        this.answeredTest.time = this.timeSpent;
        const result = await education.v2.tests.check(this.answeredTest);
        this.answerId = result.id;
        this.results = result.answers.map((answer) => {
          const newAnswer = answer;
          const ansKeys = Object.keys(newAnswer.body);
          let wrong = false;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < ansKeys.length; i++) {
            const key = ansKeys[i];
            const ansItem = newAnswer.body[key];
            // eslint-disable-next-line eqeqeq
            if (ansItem.answer != ansItem.input_answer) {
              wrong = true;
            }
            // eslint-disable-next-line eqeqeq
            if (!(newAnswer.body[key].input_answer === 'TIMEFAIL')) {
              if (ansItem.meta.type === 'boolean') {
                newAnswer.body[key].answer = ansItem.answer ? 'Да' : 'Нет';
                newAnswer.body[key].input_answer = ansItem.input_answer ? 'Да' : 'Нет';
              } else if (ansItem.meta.type === 'choice') {
                try {
                  newAnswer.body[key].answer = ansItem.meta.choices.find(
                    // eslint-disable-next-line eqeqeq
                    (val) => val.value == ansItem.answer
                  ).display_name;
                } catch (e) {
                  console.log(e);
                }
                try {
                  newAnswer.body[key].input_answer = ansItem.meta.choices.find(
                    // eslint-disable-next-line eqeqeq
                    (val) => val.value == ansItem.input_answer
                  ).display_name;
                } catch (e) {
                  console.log(e);
                }
              }
            }
          }
          newAnswer.success = !wrong;
          return newAnswer;
        });
        this.status = 'FINISHED';
      } catch (e) {
        console.log(e);
        this.status = 'ERROR';
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    formattedClock() {
      return timeUtil.formatClock(this.timeCounter);
    },
    timeSpentVerbose() {
      return timeUtil.formatClock(this.timeSpent);
    },
    currQuestionRef() {
      return this.$refs.currQ;
    },
    lastCourseName() {
      return localStorage.getItem('lastCourse');
    },
  },
  async mounted() {
    await this.initTest();
    if (this.$route.query.answerId) {
      const answero = await education.v2.tests.answers.get(this.$route.query.answerId);
      this.answerId = answero.id;
      this.results = answero.answers.map((answer) => {
        const newAnswer = answer;
        const ansKeys = Object.keys(newAnswer.body);
        let wrong = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < ansKeys.length; i++) {
          const key = ansKeys[i];
          const ansItem = newAnswer.body[key];
          // eslint-disable-next-line eqeqeq
          if (ansItem.answer != ansItem.input_answer) {
            wrong = true;
          }
          // eslint-disable-next-line eqeqeq
          if (!(newAnswer.body[key].input_answer === 'TIMEFAIL')) {
            if (ansItem.meta.type === 'boolean') {
              newAnswer.body[key].answer = ansItem.answer ? 'Да' : 'Нет';
              newAnswer.body[key].input_answer = ansItem.input_answer ? 'Да' : 'Нет';
            } else if (ansItem.meta.type === 'choice') {
              try {
                newAnswer.body[key].answer = ansItem.meta.choices.find(
                  // eslint-disable-next-line eqeqeq
                  (val) => val.value == ansItem.answer
                ).display_name;
              } catch (e) {
                console.log(e);
              }
              try {
                newAnswer.body[key].input_answer = ansItem.meta.choices.find(
                  // eslint-disable-next-line eqeqeq
                  (val) => val.value == ansItem.input_answer
                ).display_name;
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
        newAnswer.success = !wrong;
        return newAnswer;
      });
      this.status = 'FINISHED';
      clearInterval(this.timeInterval);
      console.log(answero.time);
      console.log(answero);
      this.timeSpent = answero.time;
      console.log(this.timeSpent);
    }
  },
};
</script>

<style scoped lang="scss">
.progress-bar-wrapper {
  width: 100% !important;
  background-color: #e5e5e5;
  position: relative;
  border-radius: 5px;
  height: 6px;
}
.progress-bar-inner {
  position: absolute;
  height: 6px;
  border-radius: 5px;
  background-color: #56ba41;
  transition: all 1s ease;
}
::v-deep .form-group {
  margin-bottom: 2.8rem !important;
}
</style>
