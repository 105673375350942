<template>
  <div v-bind="$attrs">
    <slot name="upperContent"></slot>
    <slot name="header"></slot>
    <template v-for="step in steps">
      <div
        :class="[contentClass, currentStep === step ? '' : 'd-none']"
        v-if="step === currentStep"
        :key="`${step}9ja9dHy7g7gdhbc`"
      >
        <slot name="contentHeader"></slot>
        <slot :name="switching ? step : `step${step}`"></slot>
        <slot name="contentFooter"></slot>
      </div>
    </template>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'multi-step-window',
  props: {
    contentClass: [String, Array, Object],
    currentStep: [Number, String],
    steps: [Number, Array, Object],
    switching: [Boolean],
  },
};
</script>
