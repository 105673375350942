<template>
  <div v-if="question.meta.question_type === 'header_question'">
    <div class="p-2 mb-3" style="border: 1px solid #d5dfe4; border-radius: 10px">
      <p class="fw-500 mb-2">{{ question.subject.body.name }}</p>
      <p style="white-space: pre-wrap">{{ question.subject.body.header }}</p>
    </div>
    <div class="mb-5 d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <p v-if="showSymbols && question.subject.body.name" class="mr-4">
          <span>Имя: {{ question.subject.body.name.length }}</span>
        </p>
        <p v-if="showSymbols && question.subject.body.header">
          <span>Описание: {{ question.subject.body.header.length }}</span>
        </p>
      </div>
      <b-button class="py-1" @click="showSymbols = true" variant="outline-default"
        >Посчитать символы</b-button
      >
    </div>
  </div>
  <div v-else>
    <div v-for="field in Object.entries(question.subject.meta.fields)" :key="field[0]">
      <div
        v-if="field[1].type === 'string'"
        class="p-2 mb-3"
        style="border: 1px solid #d5dfe4; border-radius: 10px"
      >
        <p class="fw-500 mb-2"></p>
        <p style="white-space: pre-wrap">{{ question.subject.body[field[0]] }}</p>
      </div>
      <div
        v-else-if="field[1].type === 'image'"
        class="p-2 mb-3 d-flex justify-content-between"
        style="border: 1px solid #d5dfe4; border-radius: 10px"
      >
        <div
          @click="imgopen = !imgopen"
          class="d-flex align-items-center justify-content-center"
          style="width: 95%"
        >
          <img
            class="trs--im"
            :style="`width: ${imgopen ? 95 : 30}%`"
            :src="question.subject.body[field[0]]"
          />
        </div>
        <div @click="imgopen = !imgopen" style="width: 20px">
          <img v-if="!imgopen" src="@main/assets/img/svg/fullscr.svg" alt="" />
          <img v-else src="@main/assets/img/svg/semscr.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
  },
  data: () => ({
    showSymbols: false,
    imgopen: false,
  }),
};
</script>

<style lang="scss" scoped>
.trs--im {
  transition: all 0.15s ease;
}
</style>
