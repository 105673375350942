<template>
  <div v-if="model">
    <p class="fs-14 text-right mb-1" v-if="timeInterval">Время на ответ {{ formattedClock }}</p>
    <question-subject :question="question"></question-subject>
    <meta-data-form
      ref="mtf"
      :questionsAtTime="1"
      :fieldDisplayLimit="1"
      :harmonicShow="true"
      :endCharacter="'.'"
      :disableRequiredStar="true"
      :labelStyle="'fw-500 fs-14'"
      :metadata="question.meta.fields"
      v-model="model"
    ></meta-data-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import timeUtil from '@main/func/timeUtil';
import parsers from '@main/func/parsers';
import MetaDataForm from '../reusable/forms/MetaDataForm.vue';
import QuestionSubject from './QuestionSubject.vue';

export default {
  components: { MetaDataForm, QuestionSubject },
  data: () => ({
    model: null,
    timeInterval: null,
    timeForQuestion: null,
  }),
  props: ['answers', 'question', 'timeLimit'],
  model: {
    prop: 'answers',
    event: 'input',
  },
  mounted() {
    this.model = parsers.djangoRestFrameworkMetadataParser(this.question.meta.fields, {
      nullbool: true,
    });
    this.initTime();
  },
  computed: {
    formattedClock() {
      if (!this.timeInterval) {
        return;
      }
      return timeUtil.formatClock(this.timeForQuestion);
    },
  },
  methods: {
    initTime() {
      clearInterval(this.timeInterval);
      this.timeForQuestion = this.timeLimit;
      if (this.timeForQuestion) {
        this.timeInterval = setInterval(() => {
          this.timeForQuestion -= 1;
          if (this.timeForQuestion <= 0) {
            this.$refs.mtf.currentDisplayFields.forEach((field) => {
              this.model[field[0]] = 'TIMEFAIL';
            });
          }
        }, 1000);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    this.timeInterval = null;
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.initTime();
        if (!val || !this.$refs.mtf) {
          return;
        }
        this.$refs.mtf.$v.$touch();
        if (this.$refs.mtf.$v.$anyError) {
          console.log(this.$refs.mtf.$v);
          this.$refs.mtf.$v.$reset();
          return;
        }
        this.$emit('input', this.answers.concat({ body: this.model }));
        this.$emit('next');
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .fmt-rd {
  height: 40px !important;
}
</style>
